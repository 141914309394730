.panel--default {
  margin-top: 1.3em;
  margin-bottom: 1.3em;
  margin-left: 3em;

  color: #666;
  font-size: 0.9em;

  border-left: 3px dotted $brand-color;
}

  .panel--default .panel__body {
    padding-top: 0;
    padding-bottom: 0;
  }
